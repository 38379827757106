.Calendar__day {
  border: 0.5px solid #d6d6d6;
  border-radius: 0;
  margin-bottom: 0;

  &.-blank {
    border: 0;
  }

  &.-selectedStart,
  &.-selectedBetween,
  &.-selectedEnd {
    color: $rove-black;
    border-radius: 0 !important;
  }

  &:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background-color: white;
    border-radius: 0;
    border-color: #d6d6d6;
  }
}

.Calendar__sectionWrapper {
  min-height: 20em;
}

.Calendar__weekDay {
  color: $rove-black;
}

.Calendar__monthArrow {
  background-image: url('../../assets/images/chevron-left.svg');
  background-size: contain;
}

.Calendar__monthArrowWrapper.-left {
  transform: none;
}

.Calendar__monthArrowWrapper.-right {
  transform: rotate(180deg);
}

.Calendar__monthYear {
  font-weight: lighter;
}
