@import '../../node_modules/react-phone-number-input/style.css';
@import '../../node_modules/@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';

@import 'fonts';
@import 'mixins';
@import 'constants';
@import '/src/atoms/phoneNumberInput/PhoneNumberStyles.scss';
@import '/src/atoms/dateRangePicker/DateRangePickerStyles.scss';
@import '/src/organisms/gallery/lightbox.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI,
  Roboto Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
  sans-serif;
}

body {
  max-width: $content-max-width;
  margin: 0 auto !important;
  float: none !important;
  background-color: $rove-off-white;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;

  &:focus-visible {
    outline: 0;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.scrollable {
  overflow: hidden;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.gm-style-iw {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.gm-style-iw > div {
  overflow: visible !important;
}

// Google maps info window styles
#info-window {
  overflow: hidden !important;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 6px 12px 16px 0;
  display: block;
  min-width: 266px;
  max-width: 300px;
  cursor: pointer;

  .upper-block {
    margin-bottom: 16px;
    display: flex;
    column-gap: 8px;
  }

  .upper-block-info {
    flex: 1;
  }

  .upper-block-image-wrapper {
    flex: 1;
    position: relative;
  }

  .bottom-block {
    display: flex;
    flex-direction: row;
    flex: 1;
    text-align: left;
  }

  .listing-image {
    width: 141px;
    min-width: 141px;
    height: 90px;
    object-fit: cover;
  }

  .listing-title {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .listing-address {
    font-size: 12px;
    line-height: 14px;
  }

  .listing-feature:last-child {
    div:last-child {
      border: 0;
    }
  }

  .listing-feature {
    flex: 1;
    margin-right: 1vw;

    div:nth-child(1) {
      font-size: 9px;
      line-height: 14px;
      color: #aaaaaa;
    }

    div:last-child {
      font-size: 14px;
      line-height: 14px;
      padding: 5px 0;
      border-right: 1px solid #b7b7b7;
    }
  }
}

// iw stands for InfoWindow, iw-d - for dialog. There is no better
// solution to custom the built-in popups. The only alternative is
// to create a custom popup with all the rewritten methods.

.gm-style {
  &-iw {
    background: #FCFAF8 !important;
    border-radius: 0 !important;
    border: 1px solid #000000 !important;
    box-shadow: 0 10px 10px 10px rgba(182, 182, 182, 0.25) !important;
    &-t {
      &:after {
        display: none !important;
      }
    }
    &-d {
      overflow: visible !important;
    }
  }
}
