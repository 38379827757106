@import 'mixins';
@import 'constants';

.PhoneInputCountry {
  border: 1px solid $rove-black;
  padding: 1vh 2vw;
  background-color: #ffffff;

  @include media($sm...) {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.PhoneInputInput {
  font-family: Poppins;
  border: 1px solid $rove-dark-black;
  padding: 1vh 1vw;
}
