.fslightbox-fade-in-strong {
  background: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(20px);
}
.fslightbox-nav > * {
  background: transparent !important;
}

.fslightbox-slide-btn-container > * {
  background: transparent !important;
}

.fslightbox-source {
  // opacity: 1 !important; Maybe we should enable it eventually
  // as there was a bug in the lightbox package at some point
}

